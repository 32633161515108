import React from 'react'
import PropTypes from 'prop-types'

import './about.scss'

const About = ({ heading, content }) => (
  <div
    id="about"
    className="about bg-gray text-white text-left px-14 md:px-28 py-28"
  >
    {heading && (
      <h1 className="lg:max-w-xl xl:max-w-7xl mx-auto mb-20">{heading}</h1>
    )}
    <div
      className="lg:max-w-5xl xl:max-w-6xl mx-auto"
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  </div>
)

About.propTypes = {
  content: PropTypes.string.isRequired,
}

export default About

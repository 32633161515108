import React from 'react'
import Instagram from '../../images/instagram.svg'
import LinkedIn from '../../images/linkedin.svg'
import Github from '../../images/github.svg'

import './footer.scss'

function Year() {
  const today = new Date()
  const year = today.getFullYear()
  return year
}

const Footer = () => (
  <footer className="bg-gray text-white pb-10 px-12">
    <div className="container mx-auto">
      <div className="flex flex-col md:flex-row md:justify-between">
        <div
          className="md:self-end pt-10 text-center order-1 footer-left"
          data-sal="fade"
          data-sal-easing="ease"
          data-sal-delay={0}
          data-sal-duration={500}
        >
          <img src={'/HebliefLogo.png'} className="mx-auto md:mx-0" />
          <p className="heblief uppercase opacity-50 py-6">A Heblief Company</p>
        </div>
        <div className="md:self-end text-center order-3 md:order-2 pt-10 md:pt-0">
          <div
            data-sal="fade"
            data-sal-easing="ease"
            data-sal-delay={0}
            data-sal-duration={500}
          >
            <p className="logo uppercase">Other Fox</p>
            <p className="copyright">
              Copyright | <Year />
            </p>
          </div>
        </div>
        <div
          className="order-2 md:order-3 footer-right"
          data-sal="fade"
          data-sal-easing="ease"
          data-sal-delay={0}
          data-sal-duration={500}
        >
          <ul className="social-icons flex items-center justify-center md:justify-end mt-10 mb-8">
            <li className="mr-6">
              <a
                href="https://www.linkedin.com/company/otherfox/"
                className="rounded-full h-16 w-16 bg-white flex items-center justify-center"
                target="_blank"
              >
                <LinkedIn />
              </a>
            </li>
            <li className="mr-6">
              <a
                href="https://www.instagram.com/other.fox/"
                className="rounded-full h-16 w-16 bg-white flex items-center justify-center"
                target="_blank"
              >
                <Instagram />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/otherfox"
                className="rounded-full h-16 w-16 bg-white flex items-center justify-center"
                target="_blank"
              >
                <Github />
              </a>
            </li>
          </ul>
          <ul className="legal text-center md:text-right">
            <li>
              <a className="phone" href="tel:+14047819412">
                (404) 781-9412
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
